import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FileUpload } from 'primereact/fileupload';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';

import moment from 'moment-timezone';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import fetchUpload from 'lib/fetchUpload';
import functions from 'lib/functions';
import { APPLICATION_STATUSES } from 'lib/configs';

const Hire = (props) => {
  const toast = useRef(null);
  const [hires, setHires] = useState(null);
  const [selectedHire, setSelectedHire] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hireDetail, setHireDetail] = useState(null);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });
  const [filters, setFilters] = useState({
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    employer_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    helper_name: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  useEffect(() => {
    props.setPageParams({
      alias: 'hire',
      title: '招聘申請'
    });
    getHires();
    if (functions.getUrlAttr('task') === 'process') {
      setFilters({
        ...filters,
        status: { value: 'PENDING', matchMode: FilterMatchMode.EQUALS }
      })
    }
  }, []);

  const refreshList = () => {
    setHires(null);
    getHires();
  };

  const getHires = async () => {
    const responseList = await fetchApi('hire/list');
    if (responseList.data?.hires) {
      setHires(responseList.data.hires.map((hire) => {
        return {
          ...hire,
          hire_id: hire.id.substring(0, 8).toUpperCase(),
          employer_name: hire.employer_profile.employer_name,
          helper_name: hire.helper_profiles.first_name+' '+hire.helper_profiles.last_name
        };
      }));
    } else {
      setHires([]);
    }
  };

  // const createHire = () => {
  //   setHireDetail({
  //     id: null,
  //     title: '',
  //     url: '',
  //     bannerimage: null,
  //     bannerimage_public_url: null,
  //     visibled: true,
  //   });
  //   setIsEditing(false);
  //   setIsOpenDetail(true);
  //   setIsSaving(false);
  //   setIsUnsaved(false);
  // };

  const showDetail = async (hire_id) => {
    setHireDetail(null);
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsUnsaved(false);
    setTimeout(async () => {
      const response = await fetchApi('hire/list', {
        id: hire_id
      });
      if (response.state === 1 && response.data?.hires?.length > 0) {
        setHireDetail(response.data.hires[0]);
        setIsLoadingDetail(false);
      }
    }, 100);
  };

  const formatListFields = (field, row) => {
    switch (field) {
      case 'status':
        return <Tag value={row.status} severity={APPLICATION_STATUSES.filter(item => item.id === row.status)[0].severity} />
      break;
      case 'created_at':
        return moment(row.created_at).format('YYYY年M月D日 H:mm');
      break;
    };
  };

  const filterStatus = (options) => {
    return <Dropdown value={APPLICATION_STATUSES.filter(item => item.id === options.value)[0]} onChange={(e) => options.filterApplyCallback(e.value.id)} options={APPLICATION_STATUSES} optionLabel="name" placeholder="選擇" />
  };
  const filterInputText = (options) => {
    return <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} className="p-inputtext-sm p-2" />
  };

  const onHideSidebar = () => {
    if (isSaving) {
      return false;
    }
    if (isUnsaved) {
      confirmDialog({
        message: '你還未儲存變更，確定要關閉？',
        header: '未儲存變更',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-warning',
        accept: () => {setIsOpenDetail(false)},
      });
    } else {
      setIsOpenDetail(false);
    }
  };

  const updateHireDetail = (field, value) => {
    setIsUnsaved(true);
    setHireDetail(hireDetail => {
      return {
        ...hireDetail,
        [field]: value,
      };
    });
  };
  
  const save = async () => {
    setIsSaving(true);
    const responseSave = await fetchApi('hire/save', hireDetail);
    if (responseSave.state === 1) {
      setHireDetail({
        ...hireDetail,
        id: responseSave.data.id
      })
      toast.current.show({ severity: 'success', summary: '首頁橫幅管理', detail: isEditing?'已成功儲存橫幅資料。':'已成功新增道頁橫幅。' });
      setIsUnsaved(false);
      getHires();
    } else {
      var content = '';
      switch (responseSave.error[0]) {
        default: content = `儲存資料時出現錯誤，請確認資料無誤。`; break;
        case 'title_is_required': content = `必須輸入橫幅名稱。`; break;
        case 'banner_is_required': content = `必須上傳橫幅圖片。`; break;
      }
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>{content}</li></ul>,
        type: 'danger',
      });
    }
    setIsSaving(false);
  };


  const confirmDeleteHire = () => {
    confirmDialog({
      message: '你確定要刪除這個招聘申請？ 此動作將無法復原，而僱主則可以重新招聘這位外傭。如欲禁止此僱主招聘這位外傭，請選擇 REJECTED 即可。',
      header: '刪除',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-warning',
      accept: () => {deleteHire()},
    });
  };

  const deleteHire = async () => {
    setIsSaving(true);
    const responseDelete = await fetchApi('hire/delete', {
      id: hireDetail.id
    });
    if (responseDelete.state === 1) {
      // delete locally
      setHires(hires.filter(hire => hire.id !== hireDetail.id));
      // close panel
      setIsOpenDetail(false);
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-center"
          start={<TableTopTitle title="招聘申請列表" subTitle="這裡顯示所有僱主的「招聘申請」記錄。" />}
          end={
            <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0" rounded onClick={refreshList} disabled={hires===null} />
          }
        />
      </div>
      <DataTable
        value={hires}
        selectionMode="single"
        onRowClick={(e) => {showDetail(e.data.id)}}
        emptyMessage={hires===null?'讀取中...':'未有記錄'}
        selection={selectedHire}
        stripedRows
        onSelectionChange={(e) => setSelectedHire(e.value)} dataKey="id"
        metaKeySelection={true}
        removableSort
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 50, 100]} 
        filterDisplay="row"
        filters={filters}
      >
        <Column field="status" header="申請狀態" sortable filter body={(row) => formatListFields('status', row)} showFilterMenu={false} filterElement={filterStatus} headerStyle={{ width: 200 }}></Column>
        <Column field="hire_id" header="招聘編號" sortable filter showFilterMenu={false} filterElement={filterInputText} headerStyle={{ width: 140 }}></Column>
        <Column field="employer_name" header="僱主" sortable filter showFilterMenu={false} filterElement={filterInputText}></Column>
        <Column field="helper_name" header="外傭" sortable filter showFilterMenu={false} filterElement={filterInputText} headerStyle={{ width: 400 }}></Column>
        <Column field="created_at" header="申請日期" body={(row) => formatListFields('created_at', row)} headerStyle={{ width: 200 }} sortable></Column>
      </DataTable>
      <Sidebar visible={isOpenDetail} position="right" onHide={() => {onHideSidebar()}} style={{ width: 500 }}>
        <div className="mt-1">
          {isLoadingDetail || hireDetail === null ?
            <div className="grid">
              <div className="col-12">
                <Skeleton className="mb-4 w-3 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-3rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-3rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2" height={240}></Skeleton>
              </div>
              <Divider />
              <div className="col-12 flex justify-content-end gap-2">
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
                <Skeleton className="mb-2 w-2 h-3rem"></Skeleton>
              </div>
            </div>
          :
            <>
              <div className="grid">
                <div className="col-12 flex align-items-center mb-4">
                  <h2 className="m-0 mr-3">招聘申請內容</h2>
                  <Tag value={hireDetail.status} severity={APPLICATION_STATUSES.filter(item => item.id === hireDetail.status)[0].severity} />
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-calendar mr-2"></i>申請日期</div>
                  <div className="text-xl text-justify">{moment(hireDetail.created_at).format('YYYY年M月D日 HH:mm:ss')}</div>
                </div>
                <div className="col-6 flex flex-column gap-2">
                  <label><i className="pi pi-check-circle mr-2"></i> 變更狀態</label>
                  <Dropdown value={APPLICATION_STATUSES.filter(item => item.id===hireDetail.status)[0]} onChange={(e) => updateHireDetail('status', e.value.id)} options={APPLICATION_STATUSES} optionLabel="name" />
                </div>
                <div className="col-12 flex flex-column gap-2">
                  <label><i className="pi pi-file-edit mr-2"></i> 內部備註</label>
                  <InputTextarea value={hireDetail.remarks} onChange={(e) => {updateHireDetail('remarks', e.target.value)}} rows={8} autoResize />
                </div>
              </div>
              <div className="flex justify-content-between gap-2 mt-4">
                {isEditing ? <Button icon="pi pi-trash" rounded text severity="danger" onClick={confirmDeleteHire} disabled={isLoadingDetail || isSaving} /> : <div></div>}
                <div className="flex gap-2">
                  <Button label="關閉" size="small" onClick={() => {onHideSidebar()}} outlined disabled={isSaving} />
                  <Button label={isSaving?'儲存中⋯':'儲存'} size="small" onClick={() => {save()}} disabled={isLoadingDetail || isSaving} />
                </div>
              </div>
              <Divider align="left">申請人資料</Divider>
              <div className="grid">
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-user mr-2"></i>僱主名稱</div>
                  <div className="text-xl">{hireDetail.employer_profile.employer_name}</div>
                </div>
                <div className="col-4 flex justify-content-end align-items-center">
                  <Button text label="" icon="pi pi-external-link" size="small" onClick={() => {window.open(`/employer/${hireDetail.employer_profile.user_id}`, '_blank')}} />
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-envelope mr-2"></i>電郵地址</div>
                  <div className="text-xl"><a href={`mailto:${hireDetail.employer_profile.email}`}>{hireDetail.employer_profile.email}</a></div>
                </div>
                <div className="col-5">
                  <div className="text-base text-400 mb-1"><i className="pi pi-phone mr-2"></i>聯絡電話(1)</div>
                  <div className="text-xl">{hireDetail.employer_profile.phone1}&nbsp;</div>
                </div>
                <div className="col-7">
                  <div className="text-base text-400 mb-1"><i className="pi pi-phone mr-2"></i>聯絡電話(2)</div>
                  <div className="text-xl">{hireDetail.employer_profile.phone2}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-address-book mr-2"></i>聯絡人</div>
                  <div className="text-xl">{hireDetail.employer_profile.contact_person}&nbsp;</div>
                </div>
              </div>
              <Divider align="left">外傭資料</Divider>
              <div className="grid">
                <div className="col-8">
                  <div className="text-base text-400 mb-1"><i className="pi pi-user mr-2"></i>外傭名稱</div>
                  <div className="text-xl">{hireDetail.helper_profiles.first_name} {hireDetail.helper_profiles.last_name}</div>
                </div>
                <div className="col-4 flex justify-content-end align-items-center">
                  <Button text label="" icon="pi pi-external-link" size="small" onClick={() => {window.open(`/helper/${hireDetail.users.id}`, '_blank')}} />
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-envelope mr-2"></i>電郵地址</div>
                  <div className="text-xl"><a href={`mailto:${hireDetail.users.email}`}>{hireDetail.users.email}</a></div>
                </div>
                <div className="col-5">
                  <div className="text-base text-400 mb-1"><i className="pi pi-phone mr-2"></i>聯絡電話</div>
                  <div className="text-xl">{hireDetail.helper_profiles.mobile}</div>
                </div>
                <div className="col-7">
                  <div className="text-base text-400 mb-1"><i className="pi pi-whatsapp mr-2"></i>WhatsApp</div>
                  <div className="text-xl"><a href={`https://api.whatsapp.com/send?phone=${hireDetail.helper_profiles.whatsapp}&text=Hello`} target="_blank">{hireDetail.helper_profiles.whatsapp}</a></div>
                </div>
              </div>
            </>
          }
        </div>
      </Sidebar>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Hire;
