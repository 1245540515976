import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [me, setMe] = useState(null);
  const [helpers, setHelpers] = useState(null);
  const [unverifiedHelpers, setUnverifiedHelpers] = useState(null);
  const [employers, setEmployers] = useState(null);
  const [unverifiedEmployers, setUnverifiedEmployers] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [pendingApplications, setPendingApplications] = useState(null);
  const [pendingHires, setPendingHires] = useState(null);
  const [unapprovedJobs, setUnapprovedJobs] = useState(null);
  const [announcements, setAnnouncements] = useState(null);
  const [weather, setWeather] = useState(JSON.parse('{"generalSituation":"","tcInfo":"","fireDangerWarning":"","forecastPeriod":"本港地區今晚及明日天氣預測","forecastDesc":"","outlook":""}'));

  useEffect(() => {
    props.setPageParams({
      alias: 'dashboard',
      title: '儀表板'
    });
  }, []);
  
  useEffect(() => {
    getMe();
    getWeather();
    getHelpers();
    getEmployers();
    getJobs();
    getPendingApplications();
    getPendingHires();
    getAnnouncements();
  }, []);
  
  const getMe = async () => {
    const response = await fetchApi('administrator/profile');
    if (response.state === 1) {
      setMe(response.data.user.administrator);
    }
  }
  
  const getHelpers = async () => {
    const response = await fetchApi('user/list', {
      user_type_id: 2
    });
    if (response.state === 1) {
      setHelpers(response.data.users);
      setUnverifiedHelpers(response.data.users.filter(helper => !helper.functional_profile.verified && helper.functional_profile.updated_at!==null));
    }
  }
  
  const getEmployers = async () => {
    const response = await fetchApi('user/list', {
      user_type_id: 1
    });
    if (response.state === 1) {
      setEmployers(response.data.users);
      setUnverifiedEmployers(response.data.users.filter(employer => !employer.functional_profile.verified && employer.functional_profile.updated_at!==null));
    }
  }
  
  const getJobs = async () => {
    const response = await fetchApi('job/list');
    if (response.state === 1) {
      setJobs(response.data.jobs);
      setUnapprovedJobs(response.data.jobs.filter(job => !job.approved));
    }
  }
  
  const getPendingApplications = async () => {
    const response = await fetchApi('application/list', {
      status: 'PENDING'
    });
    if (response.state === 1) {
      setPendingApplications(response.data.applications);
    }
  }
  
  const getPendingHires = async () => {
    const response = await fetchApi('hire/list', {
      status: 'PENDING'
    });
    if (response.state === 1) {
      setPendingHires(response.data.hires);
    }
  }
  
  const getAnnouncements = async () => {
    const response = await fetchApi('announcement/list');
    if (response.state === 1) {
      console.log('>>>>>>>>>>>', response);
      setAnnouncements(response.data.announcements.filter(item => item.state && item.visibled).sort((a, b) => a.ordering > b.ordering ? 1 : -1));
    }
  }
  
  const getWeather = async () => {
    try {
      const response = await fetch('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=flw&lang=tc');
      const json = await response.json();
      setWeather(json);
    } catch (e) {}
  };

  return (
    <>
      <div className="mb-4">{me?.nickname ? me.nickname+'，' : ''}你好👋 歡迎使用 Helper Station 管理平台！</div>
      <div className="grid">
        <div className="col-6 lg:col-2">
          <Panel header="👩‍💼 所有登記外傭" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/helper`);}}>
            {helpers===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{helpers.length}</span>
                <span className="text-base">位</span>
              </div>
            }
          </Panel>
          <Panel header="👩‍💼 待批核外傭履歷" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/helper?task=verify`);}}>
            {unverifiedHelpers===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{unverifiedHelpers.length}</span>
                <span className="text-base">位</span>
              </div>
            }
            
          </Panel>
        </div>
        <div className="col-6 lg:col-2">
          <Panel header="👪 所有登記僱主" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/employer`);}}>
            {employers===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{employers.length}</span>
                <span className="text-base">位</span>
              </div>
            }
          </Panel>
          <Panel header="👪 待批核僱主檔案" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/employer?task=verify`);}}>
            {unverifiedEmployers===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{unverifiedEmployers.length}</span>
                <span className="text-base">位</span>
              </div>
            }
          </Panel>
        </div>
        <div className="col-6 lg:col-2">
          <Panel header="🧰 所有工作" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/job`);}}>
            {jobs===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{jobs.length}</span>
                <span className="text-base">項</span>
              </div>
            }
          </Panel>
          <Panel header="🧰 待批核工作" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/job?task=approve`);}}>
            {unapprovedJobs===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{unapprovedJobs.length}</span>
                <span className="text-base">項</span>
              </div>
            }
          </Panel>
        </div>
        <div className="col-6 lg:col-2">
          <Panel header="❇️ 新工作申請" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/application?task=process`);}}>
            {pendingApplications===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{pendingApplications.length}</span>
                <span className="text-base">項</span>
              </div>
            }
          </Panel>
          <Panel header="✴️ 新招聘申請" className="dashboard-clickable-panel mb-3 cursor-pointer" onClick={() => {navigate(`/hire?task=process`);}}>
            {pendingHires===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div className="text-center py-4">
                <span className="text-5xl font-bold">{pendingHires.length}</span>
                <span className="text-base">項</span>
              </div>
            }
          </Panel>
        </div>
        <div className="col-12 lg:col-4">
          <Panel header="🔔 公告" className="mb-3">
            {announcements===null ?
              <div className="flex justify-content-center py-5">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <ul className="m-0 pl-4">
                {announcements.map((announcement, index) => {
                  return <li className="mb-2" key={index}>{announcement.content}</li>;
                })}
              </ul>
            }
          </Panel>
          <Panel header="🌈 天氣概況" className="mb-3">
            {weather === null ?
              <div className="flex justify-content-center py-6">
                <ProgressSpinner strokeWidth="3" style={{width: '32px', height: '32px'}} />
              </div>
            :
              <div>
                <p className="my-2">{weather.generalSituation}</p>
                <p className="my-2">{weather.outlook}</p>
              </div>
            }
            </Panel>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
