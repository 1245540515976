import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import 'primeicons/primeicons.css';

import moment from 'moment-timezone';

import MessageBox from 'components/MessageBox';
import TableTopTitle from 'components/TableTopTitle';

import fetchApi from 'lib/fetchApi';
import functions from 'lib/functions';

const MARITALS = require('jsons/maritals.json');
const NATIONALITIES = require('jsons/nationalities.json');
const RELIGIONS = require('jsons/religions.json');
const EDUCATIONS = require('jsons/educations.json');
const WORK_TYPES = require('jsons/work_types.json');
const CONTRACT_STATUSES = require('jsons/contract_statuses.json');
const SKILLS = require('jsons/skills.json');
const COOKING_SKILLS = require('jsons/cooking_skills.json');
const LANGUAGES = require('jsons/languages.json');
const DAYOFFS = require('jsons/dayoffs.json');
const LIVINGS = require('jsons/livings.json');
const JOB_TYPES = require('jsons/job_types.json');
const CHINESE_ZODIACS = require('jsons/chinese_zodiacs.json');

const Helper = (props) => {
  const toast = useRef(null);
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState({
    show: false,
    title: '',
    content: '',
    type: 'warning',
  });
  const [verifyButtonText, setVerifyButtonText] = useState('更新中⋯')
  const [filters, setFilters] = useState({
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    has_profile: { value: null, matchMode: FilterMatchMode.EQUALS },
    visibled: { value: null, matchMode: FilterMatchMode.EQUALS },
    first_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    last_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nickname: { value: null, matchMode: FilterMatchMode.CONTAINS },
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    optin: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const {
    user_id = ''
  } = useParams();

  useEffect(() => {
    props.setPageParams({
      alias: 'helper',
      title: '外傭管理'
    });
    getUsers();
    if (user_id !== '') {
      showDetail(user_id);
    }
    if (functions.getUrlAttr('task') === 'verify') {
      setFilters({
        ...filters,
        verified: { value: false, matchMode: FilterMatchMode.EQUALS },
        has_profile: { value: true, matchMode: FilterMatchMode.EQUALS },    
      })
    }
  }, []);

  const refreshList = () => {
    setUsers(null);
    getUsers();
  };

  const getUsers = async () => {
    const responseList = await fetchApi('user/list', {
      user_type_id: 2
    });
    if (responseList.data?.users) {
      setUsers(responseList.data.users.map((user) => {
        return {
          ...user,
          verified: user.functional_profile?.verified,
          visibled: user.functional_profile?.visibled,
          has_profile: user.functional_profile?.updated_at !== null,
        };
      }));
    } else {
      setUsers([]);
    }
  };

  const showDetail = async (user_id) => {
    setUserDetail(null);
    setIsLoadingDetail(true);
    setIsEditing(true);
    setIsOpenDetail(true);
    setIsSaving(false);
    setIsVerifying(false);
    setTimeout(async () => {
      const response = await fetchApi('user/list', {
        user_type_id: 2,
        user_id: user_id
      });
      if (response.state === 1 && response.data?.users?.length > 0) {
        setUserDetail(response.data.users[0]);
        setIsLoadingDetail(false);
      }
    }, 100);
  };

  const formatListFields = (field, row) => {
    switch (field) {
      case 'has_profile':
        return row.has_profile ? <Tag severity="success" value="有" /> : <Tag severity="danger" value="無" />;
      break;
      case 'verified':
        return row.verified ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
      case 'visibled':
        return row.visibled ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
      case 'created_at':
        return moment(row.created_at).format('YYYY年M月D日 H:mm');
      break;
      case 'optin':
        return row.optin ? <Tag severity="success" value="是" /> : <Tag severity="danger" value="否" />;
      break;
    };
  };

  const filterElementHasProfile = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterElementVerified = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterElementVisibled = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterElementOptin = (options) => {
    return <TriStateCheckbox value={options.value} onChange={(e) => options.filterApplyCallback(e.value)} />;
  };
  const filterInputText = (options) => {
    return <InputText value={options.value} onChange={(e) => options.filterApplyCallback(e.target.value)} className="p-inputtext-sm p-2" />
  };

  const confirmVerifyProfile = () => {
    let confirmMessage = '確定要枇核此外傭？';
    if (userDetail.functional_profile.verified) {
      confirmMessage = '確定要取消枇核此外傭？';
    }
    confirmDialog({
      message: confirmMessage,
      header: '批核外傭',
      icon: 'pi pi-info-circle',
      defaultFocus: 'accept',
      acceptClassName: userDetail.functional_profile.verified ? 'p-button-danger' : 'p-button-success',
      accept: () => {verifyProfile()},
    });
  };

  const verifyProfile = async () => {
    setIsVerifying(true);
    const to_verified = userDetail.functional_profile.verified ? 0 : 1;
    const responseVerify = await fetchApi('user/verifyProfile', {
      user_id: userDetail.id,
      verified: to_verified
    });
    if (responseVerify.state === 1) {
      toast.current.show({ severity: 'success', summary: '外傭審批', detail: to_verified?'已批核此外傭。':'已取消批核此外傭。' });
      // update this user
      setUserDetail({
        ...userDetail,
        functional_profile: {
          ...userDetail.functional_profile,
          verified: to_verified
        }
      });
      // update on list
      setUsers(users.map((user) => {
        if (user.id === userDetail.id) {
          return {
            ...user,
            verified: to_verified,
            functional_profile: {
              ...user.functional_profile,
              verified: to_verified
            }
          };
        } else {
          return user;          
        }
      }));
    } else {
      setMessageBoxData({
        show: true,
        title: '資料錯誤',
        content: <ul><li>抱歉！發生錯誤，請稍後重試。</li></ul>,
        type: 'warning',
      });
    }
    setIsVerifying(false);
  };

  const onHideSidebar = () => {
    if (!isSaving && !isVerifying) {
      setIsOpenDetail(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <Toolbar
          className="flex-column lg:flex-row p-0 border-0 bg-white align-items-center"
          start={
            <TableTopTitle title="外傭列表" subTitle="這裡顯示所有在手機App註冊為「外傭」的用戶。" />
          }
          end={
            <Button icon="pi pi-sync" size="small" className="w-2rem h-2rem p-0" rounded onClick={refreshList} disabled={users===null} />
          }
        />
      </div>
      <DataTable
        value={users}
        selectionMode="single"
        onRowClick={(e) => {showDetail(e.data.id)}}
        emptyMessage={users===null?'讀取中...':'未有記錄'}
        // loading={users===null}
        selection={selectedUser}
        stripedRows
        onSelectionChange={(e) => setSelectedUser(e.value)} dataKey="id"
        metaKeySelection={true}
        removableSort
        paginator
        rows={15}
        rowsPerPageOptions={[15, 30, 50, 100]} 
        filterDisplay="row"
        filters={filters}
      >
        <Column field="verified" header="審批" body={(row) => formatListFields('verified', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementVerified}></Column>
        <Column field="has_profile" header="檔案" body={(row) => formatListFields('has_profile', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementHasProfile}></Column>
        <Column field="visibled" header="公開" body={(row) => formatListFields('visibled', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementVisibled}></Column>
        <Column field="first_name" header="姓" sortable filter filterElement={filterInputText}></Column>
        <Column field="last_name" header="名" sortable filter filterElement={filterInputText}></Column>
        <Column field="nickname" header="暱稱" sortable filter filterElement={filterInputText}></Column>
        <Column field="email" header="電郵地址" sortable filter filterElement={filterInputText}></Column>
        <Column field="created_at" header="註冊日期" body={(row) => formatListFields('created_at', row)} headerStyle={{ width: 200 }} sortable></Column>
        <Column field="optin" header="訂閱" body={(row) => formatListFields('optin', row)} headerStyle={{ width: 80 }} sortable dataType="boolean" filter filterElement={filterElementOptin}></Column>
        {/* <Column field="user_group_name" header="用戶組"></Column> */}
      </DataTable>
      <Sidebar visible={isOpenDetail} position="right" onHide={() => {onHideSidebar()}} style={{ width: 500 }}>
        <div className="mt-1">
          {isLoadingDetail || userDetail === null ?
            <div className="grid">
              <div className="col-12">
                <Skeleton className="mb-2 w-3 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-6 h-3rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 h-2rem"></Skeleton>
              </div>
              <div className="col-12">
              <Skeleton className="mb-2 w-2"></Skeleton>
              <Skeleton className="mb-2 w-3 h-2rem"></Skeleton>
              </div>
              <Divider />
              <div className="col-12">
                <Skeleton className="mb-2 w-3 h-2rem"></Skeleton>
              </div>
              <div className="col-12 flex justify-content-center my-4">
                <Skeleton shape="circle" size="7rem" />
              </div>
              <div className="col-12">
                <Skeleton className="mb-2 w-2"></Skeleton>
                <Skeleton className="mb-2 w-10 h-2rem"></Skeleton>
              </div>
            </div>
          :
            <>
              <div className="grid user-detail-user">
                <div className="col-12 flex align-items-center">
                  <h2 className="m-0">用戶檔案</h2>
                </div>
                <div className="col-12">
                  <Message severity="info" text="用戶檔案不會被公開查閱。" />
                </div>
                <div className="col-12">
                  <div className="text-base text-400">姓名</div>
                  <div className="text-xl">{userDetail.first_name} {userDetail.last_name}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400">暱稱</div>
                  <div className="text-xl">{userDetail.nickname}</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400">電郵地址</div>
                  <div className="text-xl"><a href={`mailto:${userDetail.email}`}>{userDetail.email}</a></div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400">訂閱狀態</div>
                  <div className="mt-2">{userDetail.optin ? <Tag severity="success" value="訂閱中" className="text-lg" /> :  <Tag severity="danger" value="不訂閱" className="text-lg" />}</div>
                </div>
                <Divider />
                <div className="col-6 flex align-items-center">
                  <h2 className="m-0 mr-2">外傭檔案</h2>
                  {userDetail.functional_profile?.verified ?
                    <Tag severity="success" value="已批核" className="text-lg" />
                  :
                    <Tag severity="danger" value="未批核" className="text-lg" />
                  }
                  {userDetail.functional_profile?.visibled ?
                    <Tag severity="success" value="公開" className="text-lg ml-1" />
                  :
                    <Tag severity="danger" value="不公開" className="text-lg ml-1" />
                  }
                </div>
                <div className="col-6 flex justify-content-end">
                  {userDetail.functional_profile?.verified ?
                    <Button label={isVerifying?verifyButtonText:'按此取消批核'} size="small" severity="danger" tooltip="取消批核後，此外傭檔案將不會被公開。" tooltipOptions={{ position: 'left' }} onClick={confirmVerifyProfile} disabled={isSaving||isVerifying} />
                  :
                    <Button label={isVerifying?verifyButtonText:'按此審批此用戶'} size="small" severity="success" onClick={confirmVerifyProfile} disabled={isSaving||isVerifying} />
                  }
                </div>
                <div className="col-12">
                  <Message severity="info" text="外傭檔案在審批後及被用戶設置成「公開」後可被其他用戶查閱。" />
                </div>
                <div className="col-12 flex justify-content-center my-4">
                  <Image src={userDetail.functional_profile.avatar===null?'/images/avatar-placeholder.png':userDetail.functional_profile.avatar} alt="Avatar" width="100" height="100" preview imageClassName="border-circle border-300 border-1 object-fit-cover" />
                </div>
                <Divider align="left">外傭資料</Divider>
                <div className="col-9">
                  <div className="text-base text-400"><i className="pi pi-id-card mr-2"></i>姓名</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.first_name} {userDetail.functional_profile?.last_name}</div>
                </div>
                <div className="col-3">
                  <div className="text-base text-400"><i className={`pi pi-${userDetail.functional_profile?.gender===1?'venus':'mars'} mr-2`}></i>姓別</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.gender===1?'女':'男'}</div>
                </div>
                <div className="col-5">
                  <div className="text-base text-400"><i className="pi pi-calendar mr-2"></i>出生年份</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.year_of_birth}（約{new Date().getFullYear() - userDetail.functional_profile?.year_of_birth}歲）</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400"><i className="pi pi-heart-fill mr-2"></i>婚姻狀態</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(MARITALS.zh, userDetail.functional_profile?.marital_id)}</div>
                </div>
                <div className="col-3">
                  <div className="text-base text-400"><i className="pi pi-face-smile mr-2"></i>小孩</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.childs}個</div>
                </div>
                <div className="col-5">
                  <div className="text-base text-400"><i className="pi pi-globe mr-2"></i>國籍</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(NATIONALITIES.zh, userDetail.functional_profile?.nationality_id)}&nbsp;</div>
                </div>
                <div className="col-4">
                  <div className="text-base text-400"><i className="pi pi-home mr-2"></i>居住地</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(NATIONALITIES.zh, userDetail.functional_profile?.place_id)}&nbsp;</div>
                </div>
                <div className="col-3">
                  <div className="text-base text-400"><i className="pi pi-sparkles mr-2"></i>生肖</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(CHINESE_ZODIACS.zh, userDetail.functional_profile?.chinese_zodiac_id)}&nbsp;</div>
                </div>
                <div className="col-5">
                  <div className="text-base text-400"><i className="pi pi-sparkles mr-2"></i>宗教信仰</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(RELIGIONS.zh, userDetail.functional_profile?.religion_id)}&nbsp;</div>
                </div>
                <div className="col-7">
                  <div className="text-base text-400"><i className="pi pi-id-card mr-2"></i>持有有效護照</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.valid_passport ? <Tag severity="success" value="是" className="text-lg" /> : <Tag severity="danger" value="否" className="text-lg" />}</div>
                </div>
                <Divider align="left">聯絡資料</Divider>
                <div className="col-5">
                  <div className="text-base text-400"><i className="pi pi-phone mr-2"></i>聯絡電話</div>
                  <div className="text-xl text-justify"><a href={`tel:${userDetail.functional_profile?.mobile}`} target="_blank">{userDetail.functional_profile?.mobile}</a>&nbsp;</div>
                </div>
                <div className="col-7">
                  <div className="text-base text-400"><i className="pi pi-whatsapp mr-2"></i>WhatsApp</div>
                  <div className="text-xl text-justify"><a href={`https://api.whatsapp.com/send?phone=${userDetail.functional_profile?.whatsapp}&text=%E4%BD%A0%E5%A5%BD`} target="_blank">{userDetail.functional_profile?.mobile}</a>&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400">聯絡電郵地址</div>
                  {userDetail.functional_profile?.email ?
                    <div className="text-xl text-justify"><a href={`mailto:${userDetail.functional_profile?.email}`}>{userDetail.functional_profile?.email}</a></div>
                  :
                    <div className="text-xl text-justify">-</div>
                  }
                </div>
                <Divider align="left">履歷</Divider>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-wrench mr-2"></i>自我介紹</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.about_me}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-graduation-cap mr-2"></i>教育程度</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(EDUCATIONS.zh, userDetail.functional_profile?.education_id)}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-star mr-2"></i>年資</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.work_experience===null?'-':userDetail.functional_profile?.work_experience}年</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>技能</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(SKILLS.zh, userDetail.functional_profile?.skill).map(item => <Tag value={item} className="text-lg mr-1 mt-1"></Tag>)}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>烹飪技能</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(COOKING_SKILLS.zh, userDetail.functional_profile?.cooking_skill).map(item => <Tag value={item} className="text-lg mr-1 mt-1"></Tag>)}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-language mr-2"></i>語言技能</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(LANGUAGES.zh, userDetail.functional_profile?.language_proficiency).map(item => <Tag value={item} className="text-lg mr-1 mt-1"></Tag>)}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-wrench mr-2"></i>其他技能</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.skill_remark}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-wrench mr-2"></i>其他工作經驗</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.work_experience_remark}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-wrench mr-2"></i>其他學歷</div>
                  <div className="text-xl text-justify">{userDetail.functional_profile?.education_remark}&nbsp;</div>
                </div>
                <Divider align="left">申請職位詳情</Divider>
                <div className="col-5">
                  <div className="text-base text-400 mb-1"><i className="pi pi-wrench mr-2"></i>申請職位種類</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(JOB_TYPES.zh, userDetail.functional_profile?.job_type_id)}&nbsp;</div>
                </div>
                <div className="col-7">
                  <div className="text-base text-400"><i className="pi pi-clock mr-2"></i>工作種類</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(WORK_TYPES.zh, userDetail.functional_profile?.work_type_id)}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400"><i className="pi pi-file-check mr-2"></i>目前合約狀態</div>
                  <div className="text-xl text-justify">{functions.getObjectValueFromArrayById(CONTRACT_STATUSES.zh, userDetail.functional_profile?.contract_status_id)}&nbsp;</div>
                </div>
                <div className="col-5">
                  <div className="text-base text-400"><i className="pi pi-calendar mr-2"></i>可上工日期</div>
                  <div className="text-xl">{userDetail.functional_profile?.available_date===null?'':moment(userDetail.functional_profile?.available_date).format('YYYY年M月D日 (ddd)')}&nbsp;</div>
                </div>
                <div className="col-7">
                  <div className="text-base text-400"><i className="pi pi-dollar mr-2"></i>期望薪酬</div>
                  <div className="text-xl text-justify">HK$ {userDetail.functional_profile?.expected_salary===null?'-':userDetail.functional_profile?.expected_salary.toLocaleString()}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-sun mr-2"></i>假期偏好</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(DAYOFFS.zh, userDetail.functional_profile?.preferred_dayoff).map(item => <Tag value={item} className="text-lg mr-1 mt-1"></Tag>)}&nbsp;</div>
                </div>
                <div className="col-12">
                  <div className="text-base text-400 mb-1"><i className="pi pi-home mr-2"></i>住宿偏好</div>
                  <div className="text-xl text-justify">{functions.getObjectValuesFromArrayByIds(LIVINGS.zh, userDetail.functional_profile?.preferred_living).map(item => <Tag value={item} className="text-lg mr-1 mt-1"></Tag>)}&nbsp;</div>
                </div>
                <Divider />
                <div className="col-12 flex justify-content-end">
                  <div className="text-base text-400 mb-1">最後更新日期：{userDetail.functional_profile?.updated_at===null?'-':moment(userDetail.functional_profile?.updated_at).format('YYYY年M月D日 HH:mm')}</div>
                </div>
                <Divider />
                <div className="col-12 flex justify-content-center">
                  <a href={`/helperupdate?helper_profile_id=${userDetail.id}`} target="_blank">開啟外傭檔案編輯工具</a>
                </div>
              </div>
            </>
          }
        </div>
      </Sidebar>
      <ConfirmDialog />
      <MessageBox visible={messageBoxData.show} title={messageBoxData.title} content={messageBoxData.content} type={messageBoxData.type} onHide={() => {setMessageBoxData({...messageBoxData,show:false})}} />
      <Toast ref={toast} position="top-center" />
    </>
  );
};
  
export default Helper;
