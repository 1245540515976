import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

import MessageBox from 'components/MessageBox';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';

const Home = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);
  const [messageBoxContent, setMessageBoxContent] = useState('');

  useEffect(() => {
    functions.setBodyPage('home');
  }, []);

  const login = async () => {
    setIsLoading(true);
    const responseSignin = await fetchApi('administrator/signin', {
      email: email,
      password: password,
    });
    if (responseSignin.error) {
      // login fail
      setMessageBoxContent('電郵或密碼不正確。');
      setShowMessageBox(true);
    } else {
      functions.setCookie('hscms-user-id', responseSignin.data.user.id);
      functions.setCookie('hscms-access-token', responseSignin.data.access_token);
      navigate('/dashboard');
    }
    setIsLoading(false);
  };

  return (
    <>
      {/* <div class="bg"></div>
      <div class="bg bg2"></div>
      <div class="bg bg3"></div> */}
      <div className="flex flex-column h-full justify-content-center align-items-center">
        <Card className="w-11 lg:w-5">
          <div className="flex flex-column align-items-center">
            <Image src="images/logo.png" alt="Image" width="150" imageClassName="border-round-xl" />
            <Divider align="center">
              <span className="">登入系統</span>
            </Divider>
            <div className="flex flex-column gap-1 mt-2">
              <InputText id="email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} placeholder="電郵" />
              <Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={false}  placeholder="密碼" onKeyDown={(e) => {if (e.key==='Enter')login()}} />
              <Button label="登入" icon="pi pi-sign-in" loading={isLoading} onClick={login} disabled={isLoading} />
            </div>
          </div>
        </Card>
        <Tag className="fixed bottom-0 mb-3 right-0 mr-3">v{functions.getVersion()}</Tag>
        <p onDoubleClick={() => {setEmail('superadmin@helperstation.com.hk');setPassword('Hs2024Admin')}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        <MessageBox visible={showMessageBox} title="登入錯誤" content={messageBoxContent} type="warning" onHide={() => {setShowMessageBox(false)}} />
      </div>
    </>
  );
};
  
export default Home;
