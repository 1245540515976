import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Outlet } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';

import SidebarContainer from 'components/SidebarContainer';

import functions from 'lib/functions';
import fetchApi from 'lib/fetchApi';
import { app } from 'lib/configs';

const LayoutDashboard = (props) => {
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    // check if user's token is still valid by calling profile()
    const checkAuth = async () => {
      if (functions.getCookie('hscms-access-token') === '' || functions.getCookie('hscms-access-token') === undefined || functions.getCookie('hscms-access-token') === null) {
          functions.setCookie('hscms-administrator-id', '');
          functions.setCookie('hscms-access-token', '');
          localStorage.clear();
          navigate('/');
      } else {
        const response = await fetchApi('administrator/profile');
        if (response.state === 0) {
          functions.setCookie('hscms-administrator-id', '');
          functions.setCookie('hscms-access-token', '');
          localStorage.clear();
          navigate('/');
        } else {
          localStorage.setItem('administratorNickname', response.data.user.administrator.nickname);
        }
      }
    }
    checkAuth();
  }, []);

  useEffect(() => {
    functions.setBodyPage(props.pageParams?.alias);
    document.title = `${props.pageParams?.title} - ${app.default_title}`;
  }, [props]);

  const customHeader = (
    <div className="flex align-items-center gap-2">
        <Image src="/images/logo.png" alt="Image" width="30" imageClassName="border-round-md" />
        <span className="font-bold">Helper Station</span>
    </div>
  );

  return (
    <>
      <div className="flex flex-column">
        <div className="topbar flex flex-row justify-content-between align-items-center gap-2 p-3">
          <div className="topbar-left flex flex-row align-items-center">
            <Button icon="pi pi-bars" size="small" onClick={() => {setSidebarVisible(true)}} />
            <span className="ml-3">{props.pageParams?.title}</span>
          </div>
          <div className="topbar-right">
            <Image src="/images/logo.png" alt="Image" width="40" imageClassName="border-round-md" />
          </div>
        </div>
        <div className="main-content p-4">
          <Outlet />
        </div>
      </div>
      <Sidebar
        className="sidebar-navigation"
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        header={customHeader}
      >
        <SidebarContainer currentPageAlias={props.pageParams?.alias} />
      </Sidebar>
    </>
  )
};

export default LayoutDashboard;
